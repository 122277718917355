<template>
  <div>
    <b-row
      ><b-col cols="12" md="4" lg="5" class="mb-3">
        <date-picker
          v-model="dateRange"
          type="date"
          range
          format="DD MMM YY"
          :shortcuts="dateRangeShortcuts"
          :disabled-date="notAfterToday"
          placeholder="เลือกวันที่ต้องการค้นหา"
          input-class="form-control"
          :class="{ 'is-invalid': dateRange.length === 2 && !isDateRangeValid }"
        >
        </date-picker>
        <div v-if="!isDateRangeValid" class="invalid-feedback d-block">
          {{ dateRangeErrorMessage }}
        </div></b-col
      >
      <b-col cols="12" md="4" lg="4" class="">
        <b-form-group
          label="ดูรายงานตาม:"
          label-cols="4"
          label-align-sm="right"
        >
          <b-form-select
            class="col"
            v-model="groupBy"
            :options="options"
          ></b-form-select>
        </b-form-group>
      </b-col>

      <b-col cols="12" md="4" lg="3" class="mb-3">
        <b-button
          class="float-right mb-2 ml-2"
          variant="primary"
          :disabled="!dateRange || dateRange.length !== 2 || !isDateRangeValid"
          @click="fetchReport"
        >
          แสดงข้อมูล
        </b-button>
        <b-button
          class="float-right ml-2"
          variant="secondary"
          :disabled="
            isSendingEmail ||
            cooldownRemaining > 0 ||
            !dateRange ||
            dateRange.length !== 2 ||
            !isDateRangeValid
          "
          @click="sendEmail"
        >
          Email
        </b-button>
        <b-button
          class="float-right"
          variant="info"
          :disabled="reportList.length === 0"
          @click="excelExport('PaymentMethodTable')"
          ><i class="fas fa-file-excel"></i
        ></b-button>
      </b-col>
    </b-row>
    <b-table
      id="PaymentMethodTable"
      ref="PaymentMethodTable"
      small
      sort-icon-left
      head-variant="light"
      :busy="tableBusy"
      :items="formatedReportList"
      :fields="dynamicHeader"
      sort-by="creationDt"
      v-model="visibleRows"
      :sort-desc="true"
      :responsive="true"
      show-empty
      sticky-header="calc(100vh - 210px)"
    >
      <template #cell(index)="row">{{ row.index + 1 }}.</template>
      <template #cell(date)="row">{{ displayDate(row.value) }}</template>

      <template #custom-foot>
        <b-tr variant="secondary" class="text-bold text-right">
          <td v-for="field in dynamicHeader" :key="field.key">
            {{ sum(field.key) }}
          </td>
        </b-tr>
      </template>
      <template #empty="">
        <p class="text-center text-muted my-2">ไม่มีข้อมูลให้แสดง</p>
      </template>
    </b-table>
    <Dialog ref="Dialog"></Dialog>
    <Loading v-if="isLoading"></Loading>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { eventBus } from "@/main";

import Dialog from "@/components/modal/Dialog";
import Loading from "@/components/Loading";
import moment from "moment";

import XLSX from "xlsx";

export default {
  name: "PaymentMethodReport",
  components: {
    Dialog,
    Loading,
  },
  data() {
    return {
      isLoading: false,

      dateRange: [],
      groupBy: "day",
      options: [
        { value: "day", text: "วัน" },
        { value: "month", text: "เดือน" },
      ],

      //table
      tableBusy: false,

      fields: [
        {
          key: "index",
          label: "",
        },
        {
          key: "date",
          label: "วันที่",
        },
      ],
      reportList: [],
      rawTableHeader: [],

      visibleRows: [],

      loadingTime: 0,

      isSendingEmail: false,
      cooldownRemaining: 0,
      cooldownTimer: null,
      cooldownPeriod: 60, // กำหนดเวลา cooldown 15 วินาที ไว้ในนี้
    };
  },
  created() {
    this.init();
  },
  computed: {
    ...mapGetters({
      userInfo: "moduleUser/getUserInfo",
      getReportDateRange: "moduleReport/getReportDateRange",
      dateRangeShortcuts: "moduleMaster/getDateRangeShortcuts",
    }),

    dateFrom() {
      return this.dateRange[0]
        ? moment(this.dateRange[0]).startOf("day").format()
        : "";
    },
    dateTo() {
      return this.dateRange[1]
        ? moment(this.dateRange[1]).endOf("day").format()
        : "";
    },
    today() {
      let today = new Date();
      today.setHours(0, 0, 0, 0);
      return today;
    },
    formatedReportList() {
      return this.reportList.map(i => {
        let payment = i.receiptPayment.reduce((prev, curr) => {
          let obj = {};
          let key = "paymentId" + curr.keyName;
          obj[key] = curr;
          return Object.assign(prev, obj);
        }, {});
        let totalAmount = i.receiptPayment.reduce((prev, curr) => {
          return prev + curr.value;
        }, 0);
        return {
          date: i.creationDt,
          ...payment,
          totalAmount,
        };
      });
    },
    dynamicHeader() {
      let formatedHeader = this.rawTableHeader.map(i => {
        return {
          key: "paymentId" + i.keyName + ".value",
          label: i.name,
          class: "text-right",
          thClass: "narrow-spacing",
        };
      });
      let totalAmount = {
        key: "totalAmount",
        label: "รวม",
        class: "text-right font-weight-bold",
      };
      return [...this.fields, ...formatedHeader, totalAmount];
    },
    emailLastSentTime: {
      get() {
        const savedTime = localStorage.getItem("emailLastSentTime");
        return savedTime ? parseInt(savedTime) : null;
      },
      set(value) {
        if (value) {
          localStorage.setItem("emailLastSentTime", value.toString());
        } else {
          localStorage.removeItem("emailLastSentTime");
        }
      },
    },
    isDateRangeValid() {
      if (!this.dateRange || this.dateRange.length !== 2) return true;

      const startDate = moment(this.dateRange[0]);
      const endDate = moment(this.dateRange[1]);

      // Calculate the difference in days
      const diffDays = endDate.diff(startDate, "days");

      // Check if the difference is more than 31 days (approximately one month)
      return diffDays <= 31;
    },

    dateRangeErrorMessage() {
      return this.isDateRangeValid ? "" : "ช่วงเวลาต้องไม่เกิน 1 เดือน";
    },
  },
  watch: {},
  methods: {
    ...mapActions({
      fetchReportPaymentMethod: "moduleReport/fetchReportPaymentMethod",
      emailReportPaymentMethod: "moduleReport/emailReportPaymentMethod",
      setStateReportDateRange: "moduleReport/setStateReportDateRange",
    }),
    init() {},
    moment() {
      return moment();
    },
    formatDate(date) {
      if (date) {
        return moment(date).locale("th").format("D MMM YY");
      } else {
        return "";
      }
    },
    displayDate(date) {
      if (this.groupBy === "year")
        return "ปี " + moment(date, "YYYY").locale("th").format("YYYY");
      if (this.groupBy === "month")
        return moment(date, "YYY-MM").locale("th").format("MMM YY");
      return moment(date, "YYY-MM-DD").locale("th").format("D MMM YY");
    },

    showDialogToast(variant, textDetail) {
      this.$refs.Dialog.showToast(variant, textDetail);
    },
    sum(key) {
      let splitStr = key.split(".");
      return this.visibleRows.reduce((accum, item) => {
        let data =
          splitStr.length > 1 ? item[splitStr[0]][splitStr[1]] : item[key];
        if (typeof data != "number") return null;
        return accum + data;
      }, null);
    },
    generatePDf() {
      eventBus.$emit(
        "generatePDF",
        this.$refs["PaymentMethodTable"].$el,
        this.$refs.header,
        this.$refs.footer
      );
    },

    async sendEmail() {
      if (!this.validateDateRange()) return;

      if (this.dateFrom === "" || this.dateTo === "") {
        this.$refs.Dialog.showAlertInfo(
          "ข้อมูลไม่ครบถ้วน",
          "กรุณาเลือกช่วงวันที่ที่ต้องการก่อนส่งรายงาน",
          "warning"
        );
        return;
      }

      // ตรวจสอบว่ากำลังส่งอีเมลอยู่หรือไม่
      if (this.isSendingEmail || this.cooldownRemaining > 0) {
        return;
      }

      try {
        this.isSendingEmail = true;

        await this.emailReportPaymentMethod({
          clinicUrl: this.$route.params.clinicUrl,
          branchUrl: this.$route.params.branchUrl,
          dateFrom: this.dateFrom,
          dateTo: this.dateTo,
          groupBy: this.groupBy,
        });

        // บันทึกเวลาที่ส่งอีเมลสำเร็จ
        this.emailLastSentTime = Date.now();

        this.$refs.Dialog.showAlertInfo(
          "การส่งรายงานเสร็จสมบูรณ์",
          "ระบบได้จัดส่งรายงานไปยังอีเมลที่ได้ลงทะเบียนไว้แล้ว กระบวนการอาจใช้เวลาประมาณ 5-15 นาที หากไม่พบอีเมลในกล่องจดหมายหลัก กรุณาตรวจสอบในโฟลเดอร์จดหมายขยะ (Junk Mail)",
          "success"
        );

        // เริ่มการนับถอยหลัง
        this.startCooldown(this.cooldownPeriod);
      } catch (error) {
        this.$refs.Dialog.showAlertInfo(
          "เกิดข้อผิดพลาด",
          "ไม่สามารถส่งรายงานได้ กรุณาลองใหม่อีกครั้ง",
          "error"
        );
        console.error("Error sending email report:", error);
      } finally {
        this.isSendingEmail = false;
      }
    },

    startCooldown(seconds) {
      // ล้าง timer เดิมถ้ามี
      if (this.cooldownTimer) {
        clearInterval(this.cooldownTimer);
      }

      this.cooldownRemaining = seconds;

      this.cooldownTimer = setInterval(() => {
        this.cooldownRemaining--;

        if (this.cooldownRemaining <= 0) {
          clearInterval(this.cooldownTimer);
          this.cooldownTimer = null;
        }
      }, 1000);
    },
    fetchReport() {
      if (!this.validateDateRange()) return;

      if (this.dateFrom === "" || this.dateTo === "") return;

      this.setStateReportDateRange(this.dateRange);

      this.isLoading = true;
      this.fetchReportPaymentMethod({
        clinicUrl: this.$route.params.clinicUrl,
        branchUrl: this.$route.params.branchUrl,
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
        groupBy: this.groupBy,
      })
        .then(res => {
          this.reportList = res.data.item;
          this.rawTableHeader = res.data.header.receiptPayment;
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    excelExport(id) {
      document.querySelectorAll(".sr-only").forEach(e => e.remove());

      var tbl = document.getElementById(id);
      var ws = XLSX.utils.table_to_sheet(tbl);
      let filename = "report.xlsx";
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws);

      XLSX.writeFile(wb, filename, {
        // type: "string",
      });
    },
    notAfterToday(date) {
      // First check if date is in the future
      if (date > new Date(new Date().setHours(0, 0, 0, 0))) {
        return true;
      }

      // If a start date is already selected, check that the new end date doesn't exceed one month
      if (this.dateRange && this.dateRange.length === 1 && this.dateRange[0]) {
        const startDate = moment(this.dateRange[0]);
        const currentDate = moment(date);

        // If user is selecting a date before the start date, it will become the new start date
        // so we don't need to validate the range here
        if (currentDate.isBefore(startDate)) {
          return false;
        }

        // If user is selecting a date after the start date, ensure it's not more than 31 days later
        const diffDays = currentDate.diff(startDate, "days");
        return diffDays > 31;
      }

      return false;
    },
    validateDateRange() {
      if (!this.dateRange || this.dateRange.length !== 2) {
        this.$refs.Dialog.showAlertInfo(
          "ข้อมูลไม่ครบถ้วน",
          "กรุณาเลือกช่วงวันที่ที่ต้องการก่อนแสดงรายงาน",
          "warning"
        );
        return false;
      }

      if (!this.isDateRangeValid) {
        this.$refs.Dialog.showAlertInfo(
          "ช่วงวันที่ไม่ถูกต้อง",
          "กรุณาเลือกช่วงวันที่ไม่เกิน 1 เดือน",
          "warning"
        );
        return false;
      }

      return true;
    },
  },
  mounted() {
    if (this.getReportDateRange[0] && this.getReportDateRange[1]) {
      this.dateRange = this.getReportDateRange;
    } else {
      this.dateRange = [this.today, this.today];
    }

    const lastSentTime = this.emailLastSentTime;
    if (lastSentTime) {
      const elapsedSeconds = Math.floor((Date.now() - lastSentTime) / 1000);

      if (elapsedSeconds < this.cooldownPeriod) {
        // ยังอยู่ในช่วง cooldown
        this.startCooldown(this.cooldownPeriod - elapsedSeconds);
      }
    }
  },
  updated() {},
  beforeDestroy() {
    // ล้าง timer เมื่อ component ถูกทำลาย
    if (this.cooldownTimer) {
      clearInterval(this.cooldownTimer);
    }
  },
};
</script>

<style>
</style>
